import React, { Fragment } from 'react'
import { BreadCrumbs } from '../../containers'
import { Link } from 'react-router-dom'
import { getRouteByName } from '../../routes'
import RouteTabs from '../RouteTabs'
import trans from '../../trans'
import PersonalDataForm from './PersonalData/PersonalDataForm'
import { Loader } from '../ui/LoadingOverlay/Loader'
import UserCard from './UserCard'
import { countries } from '../../store/app/countries'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { languages } from '../../store/app/languages'
import { isEmpty } from 'lodash'
import Roles from './Roles'
import Substitutions from './Substitutions/Substitutions'
import Assistants from './Assistants'
import SecurityTab from './Security/SecurityTab'
import ChargeCards from './ChargeCards/ChargeCards'
import ExternalMeInCompany from '../CompanyHierarchy/ExternalMeInCompany'
import ExternalCompanyHierarchy from './ExternalCompanyHierarchy'
import {
  fetchUserProfile,
  isUserProfileLoading,
  makeGetUserProfileBySlugSelector,
  setUserProfile,
  updateUserGroups,
} from '../../store/app/user-profile/userProfileSlice'

class ExternalUserProfilePage extends React.Component<any, any> {
  componentDidMount() {
    const {
      match: {
        params: { slug },
      },
      fetchUserProfile,
    } = this.props

    fetchUserProfile(slug)
  }

  renderContent = () => {
    const {
      countries: {
        selectors: { countries },
      },
      languages: {
        selectors: { languages },
      },
      updateUserGroups,
      setUserProfile,
      profile,
      match,
    } = this.props

    const tabs = [
      {
        id: 'substitutions',
        path: `${match.path}/substitutions`,
        title: trans('user.substitutions'),
        component: <Substitutions subjectUser={profile} isFromExternal={true} />,
      },
      {
        id: 'assistants',
        path: `${match.path}/assistants`,
        title: trans('user.assistants-list'),
        component: <Assistants subjectUser={profile} isFromExternal={true} />,
      },
      {
        id: 'personal-data',
        path: `${match.path}/personal-data`,
        title: trans('user.personal-data'),
        component: (
          <PersonalDataForm
            currentUser={false}
            user={profile}
            countries={countries}
            languages={languages}
          />
        ),
      },
      {
        id: 'security',
        path: `${match.path}/security`,
        title: trans('user.security'),
        component: <SecurityTab subjectUser={profile} />,
      },
      {
        id: 'charge-cards',
        path: `${match.path}/charge-cards`,
        title: trans('user.charge-cards'),
        component: <ChargeCards subjectUser={profile} />,
      },
      {
        id: 'company',
        path: `${match.path}/company`,
        title: trans('user.company-hierarchy'),
        component: <ExternalCompanyHierarchy subjectUser={profile} />,
      },
      {
        id: 'roles',
        path: `${match.path}/roles`,
        title: trans('settings-page.roles'),
        component: <Roles user={profile} setUser={setUserProfile} update={updateUserGroups} />,
      },
    ]

    return (
      <div className='settings-page__account-container'>
        <header className='section__header'>
          <h2 className='h2 section__header-title'>{trans('settings-page.my-profile')}</h2>
        </header>

        <UserCard currentUser={false} setUserProfile={setUserProfile} user={profile} />

        {profile ? <RouteTabs match={match} items={tabs} defaultTab={tabs[0].id} /> : ''}
      </div>
    )
  }

  render() {
    const { profile, isFetching } = this.props

    console.log('profile', profile)

    return (
      <div className='settings-page'>
        <BreadCrumbs>
          <Link to={getRouteByName('main', 'dashboard')}>{trans('global.dashboard')}</Link>
          <Link to={getRouteByName('main', 'users')}>{trans('main-menu.users')}</Link>
          {!isEmpty(profile) && (
            <Link to={getRouteByName('main', 'external-user', { slug: profile.slug })}>
              {profile.full_name}
            </Link>
          )}
        </BreadCrumbs>

        <section className='section section--no-border settings-page__section has-loader'>
          {isFetching || isEmpty(profile) ? (
            <Loader />
          ) : (
            <Fragment>
              {this.renderContent()}
              <aside className='settings-page__aside'>
                <ExternalMeInCompany
                  user={profile}
                  subjectUser={profile}
                  link={
                    getRouteByName('main', 'external-user', { slug: profile.slug }) + '/company'
                  }
                />
              </aside>
            </Fragment>
          )}
        </section>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const {
    match: {
      params: { slug },
    },
  } = props

  return {
    profile: makeGetUserProfileBySlugSelector()(state, slug),
    isLoading: isUserProfileLoading(state),
  }
}

const mapDispatchToProps = (dispatch, props) => {
  return bindActionCreators(
    {
      fetchUserProfile,
      updateUserGroups,
      setUserProfile,
    },
    dispatch,
  )
}

const withCountries = countries(false, false)
const withLanguages = languages(false, false)
const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, withCountries, withLanguages)(ExternalUserProfilePage)
